/* ======================================================================== */
/* 15. figureInfo */
/* ======================================================================== */
.figure-info__option
	margin-top: 0.5em
	margin-bottom: 0.5em
	color: $brown-grey
.figure-info__value
	margin-top: 0
	margin-bottom: 0
