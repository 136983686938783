/* ======================================================================== */
/* 17. figureImage */
/* ======================================================================== */
.figure-image
	display: block
	margin-bottom: 0
.figure-image__link
	display: block
.figure-image__wrapper-img
	position: relative
	overflow: hidden
.figure-image__wrapper-img-zoom
	+trans1
	transform: scale(1.25)
	transform-origin: center center
.figure-image__caption
	display: block !important
.figure-image__wrapper-caption
	+trans1
	transition-delay: 0s
